import React from "react";
import './SystemInformationTable.css';
import { VirtualTable} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';


let systemInfoTableRows = [];

function SystemInformationTable(props){

    systemInfoTableRows = get_environment_values(props)
    
    let systemInfo_tableRows =  [...systemInfoTableRows];
    let columnNames=
      [
        {
          capitalized: true,
          displayName: 'Template Id',
          key: 'template_id',
          width: 300
        },
        {
          capitalized: true,
          displayName: 'Template Name',
          key: 'template_name',
          width: 600
        },
        {
          displayName: 'Template Description',
          key: 'template_description',
          width: 900
        }
      ]

      return(
        <div className="SystemInfoTable">
            <VirtualTable 
              loading={props.isLoading}
              columnNames={columnNames}
              rows={systemInfo_tableRows}
              hasCheckboxes={false}
              hasActions={false}
              editable={false}
              sortable={true}  
            />
        </div>
      )



    // var systemInfo = get_environment_values(props)
    // systemInfo.sort((a, b) => {
    //     let fa = a.template_name.toLowerCase(),
    //         fb = b.template_name.toLowerCase();
    
    //     if (fa < fb) {
    //         return -1;
    //     }
    //     if (fa > fb) {
    //         return 1;
    //     }
    //     return 0;
    // });

    // return (
    //     <div >
    //         <table id="system_info_table">
    //             <thead>
    //                 <tr>
    //                     <th style={{width:'15%'}} id='templateId' scope="col" >Template Id </th>
    //                     <th style={{width:'15%'}} id='templateName' scope="col" >Template Name </th>
    //                     <th style={{width:'50%'}} id='templateDesc' scope="col" >Template Description </th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //             {systemInfo.map((systemInfo, index) => (
    //                 <tr key={index} id={"systemInfo"+index}>
    //                     <td id={"templateId"+index}>{ systemInfo.template_id}</td>
    //                     <td id={"templateName"+index}>{ systemInfo.template_name}</td>
    //                     <td id={"templateDescription"+index}>{ systemInfo.template_description}</td>
    //                 </tr>
    //             ))}
    //             </tbody>  
    //         </table>
    //     </div>
    // );
}

function get_environment_values(props)
{
    var jsonData = props.environemntRecords
    const environment = [ ];
    for( var i = 0; i < jsonData.Count; i++){
        if(jsonData.Items[i].template_id != '0'){
            environment.push({
                template_id:jsonData.Items[i].template_id,
                template_name : jsonData.Items[i].template_name,
                template_description : jsonData.Items[i].template_description
            })
        }
    }
    return environment;
}

export default SystemInformationTable;