import React, {useState, useEffect} from 'react';
import { NumericStepper, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import $ from 'jquery';

function GivenTime(props) {

    const [hours, setHours] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const open = Boolean(anchorEl);
    const min = 0;
    const max = 40;
    
    // Removes the old GivenTime component and resets its value to 0 whenever the props.lmsSelected changes.
    useEffect(() => {

        return () => {
            setHours(0)
        }
    }, [props.lmsSelected])

    // If component is not required, remove it from the form
    if(props.lmsSelected === false){
        return null
    }
    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= min && value <= max) {
            setHours(value);
        }
    };
    return(<>
     
                
            <div className="col-7 col-sm-4 col-md-3" id="GivenTimeRow">
                   
            <NumericStepper id="GivenTime" name="GivenTime" data-testid="verint-numeric-desktopNumber" onChange={handleChange} defaultValue={hours}  value={hours}
            min={min}  max={max} type="number" />
                </div>
              
        </>
    )
}
export default GivenTime

 