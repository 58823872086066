import React, {useState, useEffect} from 'react';
import { Label, NumericStepper} from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function NumberOfUsers(props) {

    const [NumberOfUsers, setNumberOfUsers] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const min = 0;
    const max = 10;

    useEffect(() => {
        return () => {
            setNumberOfUsers(0)
        }
    }, [props.AdditionalVcorpUsers])

    // Updating for Edit Form 
    
    useEffect(() => {
        if (props.AdditionalVcorpUsers){
            
            setNumberOfUsers(props.AdditionalVcorpUsers)
           }
    },[props.AdditionalVcorpUsers])

    //If component is not required, remove it from the form
    if(props.AdditionalVcorpUsers === false){
        return null
    }
   console.log("props.AdditionalVcorpUsers",props.AdditionalVcorpUsers)
    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= min && value <= max) {
            setNumberOfUsers(value);
        }
    };
    return(
        <div >
               
                 <NumericStepper className="" 
                 style={{marginTop :"-9px"}}
                 type="number" 
                 id="NumberOfUsers" 
                 min={min}
                 max={max}
                 name="NumberOfUsers" 
                 onChange={handleChange}
                 value={NumberOfUsers} 
                 disabled= {props.editForm ? true : (!props.AdditionalVcorpUsers) }
                 placeHolderText = "Enter number of users.."
                    />
            </div>
    )
}
export default NumberOfUsers
