import React from 'react';
import './SystemInformationTable.css'
import Container from '@material-ui/core/Container'
import '@verint/lux_icon_font/verint_lux.css';

function SystemInformationDescription(props){
    
    return(
        <>    
            <br></br>
            <Container>
                <div class='descContainer'>
                    <div class="systemInfoDescription" dangerouslySetInnerHTML={{ __html: props.description }}>
                        
                    </div>
                </div>    
            </Container>
        </>
    );
}

export default SystemInformationDescription;