import React, {useState, useEffect} from 'react';
import { CheckboxV2, Label} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function IvaProCheckbox(props) {

    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_ivapro_checkbox(props) === false){
            props.setIvapro(false)
        }        
    }, [props.environmentSelected])

    
    if(should_display_ivapro_checkbox(props) === false){
        return null
    }

    return <>
        {/*  IvaPro  */}
        <tr className='reqForm_tablerow'>
                <td className='td_row_leftvalue'>

                            <CheckboxV2 className="verint-CheckboxV2-regular" id="IvaPro" style={{marginTop :"3px"}}
                                            name="IvaPro" type = "checkbox" value = {props.ivapro} disabled={props.disabled} checked={props.checked}
                                            onChange={(e) => {props.setIvapro(!props.ivapro);}}/>
                                
                            <Label id="IVAPro_label" className="verint-regular-label" forhtml="IvaPro">Voice Required </Label>
                            <OverlayText tooltipText={"Only select this option if you require Voice capability. This must be deployed manually, and there are limited server resources to support. "}></OverlayText>
                                    
                                    

                    
                </td>
        </tr>
    </>        
}

export default IvaProCheckbox;
function should_display_ivapro_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].IvaPro  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
