import React, { useRef, useEffect,Component,useState } from "react";
import { DatePickerV2, LuxStylesProvider,Label, Popover} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import OverlayText from "./OverlayText";
import $ from 'jquery';

function StartDate(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
         
      $("[name='ProvisionDate']").attr('required','')
    }, []);
    // display datetime for startdate
    return <>
      
                <div >
                  <LuxStylesProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                         <DatePickerV2  startDate={props.startDate} setStartDate = {props.setStartDate} endDate={props.endDate}  required
                        minDate={new Date()} maxDate={props.endDate} selected={props.startDate}
                        onChange={(date) => { props.setStartDate(date)}} onKeyDown={() => {return false}} name="ProvisionDate" format='yyyy-MM-dd' /></LocalizationProvider>
                  </LuxStylesProvider>
                </div>
          
    </>        
}

export default StartDate;