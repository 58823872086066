import React, { useEffect, useState } from "react";
import { ComboBox } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
 
function CognitoGroup(props) {
  const [selectedOptionValue, setSelectedOption] = useState(props.cognitoGroupValue);
  const [cognitoGroupChanged, setCognitoGroupChanged] = useState(props.changedValue || '');
 
  useEffect(() => {
    const initialValue = props.cognitoGroupValue || '';
    setSelectedOption(initialValue);
    if (props.changedValue) {
      setCognitoGroupChanged(props.changedValue);
    }
  }, [props.changedValue, props.cognitoGroupValue]);
 
  const cognitoGroupsArray = JSON.parse(props.cognitoGroups);
  const optionsArray = cognitoGroupsArray.map((group) => ({
    value: group,
    key: group,
  }));
 
  const handleCognitoGroup = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setCognitoGroupChanged(selectedOption.value);
  };
 
  if (props.item != null && !props.item.IsAdmin) {
    return null;
  }
 
  const selectedValue = optionsArray.find((o) => o.value === cognitoGroupChanged) || optionsArray.find((o) => o.value === selectedOptionValue);
  // const selectedValue =  optionsArray.find((o) => o.value === cognitoGroupChanged) ? optionsArray.find((o) => o.value === cognitoGroupChanged) : optionsArray.find((o) => o.value === selectedOptionValue);
 
  return (
    <tr className="editForm_table-row" style={{ border: "hidden", paddingBottom: "15px" }}>
      <td className="td_row_leftvalue">Cognito Groups</td>
      <td className="td_key" style={{ paddingLeft: "15px" }}>
        <ComboBox
          name="cognitoGroup"
          id="cognitoGroup"
          optionKey="key"
          options={optionsArray}
          onChange={handleCognitoGroup}
          value={selectedValue}
        />
      </td>
    </tr>
  );
}
 
export default CognitoGroup;