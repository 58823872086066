import React, { useRef, useEffect,Component,useState } from "react";
import {Label} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
import $ from 'jquery';

function LabDurationLabel(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
         
      $("[name='ProvisionDate']").attr('required','')
    }, []);
    return <>    
    
         
              <Label className="verint-regular-label" forhtml="LabDurationLabel" >Duration needed </Label><div className="required-field"></div>
              <OverlayText tooltipText={"The date that your lab will be deployed. Setting a date in the future, will result in your lab email being delivered at a later date."}></OverlayText>

          
    </>        
}

export default LabDurationLabel;