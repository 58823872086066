import React, { useState, useEffect } from "react";
import { CheckboxV2, Label } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

/* Set the value of Call Summary checkbox */
function ALBCheckbox(props) {
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (should_display_ALB_checkbox(props) === false) {
      setChecked(false);
    }
    // checking for the editform property from current req
    if (props.editForm === "true") {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  //If selected environment cannot use the checkbox, hide
  if (should_display_ALB_checkbox(props) === false) {
    return null;
  }

  // If DaVinci Checkbox is set to true, only then display ALB Required Checkbox
  return (
    <>
      {/*  Call Summary Checkbox  */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="ALBRequired"
            style={{ marginTop: "3px" }}
            name="ALBRequired"
            type="checkbox"
            value={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
            disabled={props.disabled}
            checked={props.checked}
          ></CheckboxV2>

          <Label
            id="ALBRequired_label"
            className="verint-regular-label"
            forhtml="ALB_Required"
          >
            Recording ALB{" "}
          </Label>
          <OverlayText
            tooltipText={
              "Select this option if you are working with Avaya AXP or Genesys recording"
            }
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default ALBCheckbox;

function should_display_ALB_checkbox(props) {
  if (props.daVinciChecked === true) {
    return true;
  }
  //checking the editform property from current request
  if (props.editForm === "true") {
    return true;
  }
  return false;
}
