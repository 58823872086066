import React, {useState, useEffect} from 'react';
import { NumericStepper, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function PodNumber(props) {

    const [numberOfPods, setNumberOfPods] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const min = 0;
    const max = 15; 

    // After every render, if the selected environment changes, check if the environment needs desktops or not.
    // Resets and hides the DesktopNumber component if a technical environment is selected
    useEffect(() => {
        if(display_pod_number_input(props) === false){
            setNumberOfPods(1)
        }        
    }, [props.environmentSelected])

    // Hide DesktopRequired component if the current environment is not an application deployment
    if(display_pod_number_input(props) === false){
        return null
    }

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= min && value <= max) {
            setNumberOfPods(value);
        }
    };

    return <>
        {/* number of pods */}
        
        <tr className='reqForm_tablerow'>
                   <td className="td_row_leftvalue">
                        <Label id="NumberOfPods_label"className="verint-regular-label"   htmlFor="NumberOfPods">Number of Pods </Label>
                        <OverlayText tooltipText={"Use this option to confirm the number of labs you require. For example, if you're delivering a training class,you need to specify the number of pods required to cover the number of delegates you have attending. It is recommended you deploy a few extra labs for redundancy purposes."}></OverlayText>
                    </td>
                    <td className="td_row_rightvalue">
                        <NumericStepper className="" 
                            style={{marginTop :"-9px"}}
                            type="number" 
                            id="NumberOfPods" 
                            min={min}
                            max={max}
                            name="NumberOfPods" 
                            onChange={handleChange}
                            value={numberOfPods} 
                            placeHolderText = "Enter number of Pods"
                                />  
                    </td>
                </tr>
        
    </>       
}

function display_pod_number_input(props){
  
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].display_pod_number_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
export default PodNumber;