
import React, {useState} from 'react';
import {Label,RadioGroup, Radio} from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
function Billable(props) {
        
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState("yes")
  const open = Boolean(anchorEl);

    // If selected environment is cannot use the billable input, hide
    if(display_of_input(props.group) === false){
        return null
    }

    const handleChange = (event)=>{
      setSelected(event.target.value);
    }

    return <>
      

          <tr className='reqForm_tablerow'>
              <td className="td_row_leftvalue" >
                <Label id="billable_label" className="verint-regular-label" forhtml="billable" >Billable? </Label>
                <OverlayText tooltipText={"If the lab is being used for a purpose that is chargeable. Examples include,certain training scenarios, development work for a customer or any other purpose where Verint will receive payment for the results derived from the lab."}></OverlayText>
              </td>
               
              <td className="td_row_rightvalue" >  
                    <RadioGroup
                    id="Billable" 
                    name="Billable"
                    value = {selected}
                    onChange={handleChange}
                  >
                  <Radio 
                    data-testid="verint-radio-group-control-1"
                    label="Billable"
                    value="yes"
                    
                  />
                  <Radio
                    label="Not billable"
                    value="no"
                  />
                  </RadioGroup>
                </td>
                </tr>
         
          
    </>        
}

export default Billable;

function display_of_input(group){
    if(group === "PreSales" || group === "EMEAPreSales" || group === "APACPreSales"|| group === "OEM"){
        return false
    }
    else{
        return true
    }

}