import React from 'react';

function SubmitButton(props) {
    return <>
        {/* Submit button */}
        <div class="row">
                <div class="col-3 col-sm-2 col-md-4 col-lg-4 col-xl-4"></div>
                <div class="col-6 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                    <button type="submit" class="btn btn-primary form-control">Submit</button>
                </div>
            </div>
    </>
        
        
  }

  SubmitButton.defaultProps = {
    title: 'Verint',
}

export default SubmitButton;