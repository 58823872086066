import React, {useEffect,useState} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";
function LuxCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_lux_checkbox(props) === false){
            props.setLuxui(false)
        }        
    }, [props.environmentSelected])

  
     //If selected environment is cannot use the checkbox, hide
     if(should_display_lux_checkbox(props) === false){
        return null
    }

    return <>
        {/* LuxUiURL */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                              
                                <CheckboxV2 className="verint-CheckboxV2-regular" id="LuxUI" style={{marginTop :"3px"}}
                                name="LuxUI" type = "checkbox"  value = {props.Luxui}  disabled={props.disabled} checked={props.checked}
                                onChange={(e) => {props.setLuxui(!props.Luxui);}}></CheckboxV2> 
                                <Label id="Lux_label"className="verint-regular-label"   forhtml="LuxUI">LUX UI </Label>
                                <OverlayText tooltipText={"Select this option if you require the new LUX UI"}></OverlayText>
                                     
                </td>
            </tr>
    </>        
}

export default LuxCheckbox;

function should_display_lux_checkbox(props)
{
    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].LuxUI === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}