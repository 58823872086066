import React, { useState, useEffect } from "react";
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';

function CloudWFEDemoCheckbox(props) {
  const [checked, setChecked] = useState(false);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (display_cloud_wfe_demo_checkbox(props) === false) {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  // If selected environment is cannot use the checkbox, hide
  if (display_cloud_wfe_demo_checkbox(props) === false) {
    return null;
  }

  return (
    <>
      {/*  MS Teams Live Recording  */}
      <tr className='reqForm_tablerow'>
              <td className='td_row_leftvalue'>
                      <CheckboxV2  disabled className="verint-CheckboxV2-regular" id="CloudWFEDemo" style={{marginTop :"3px"}}
                            name="CloudWFEDemo"   type="checkbox" checked value={checked}  onChange={(e) => { setChecked(!checked);}} ></CheckboxV2>
                          
                          <Label
                            id="CloudWFEDemo_label"
                            className="verint-regular-label"
                            forhtml="CloudWFEDemo"
                             
                          >
                            Additional Speech App
                        </Label>
                </td>
          </tr>
    </>
  );
}

export default CloudWFEDemoCheckbox;

function display_cloud_wfe_demo_checkbox(props) {
  var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].cloud_wfe_demo_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}