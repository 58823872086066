import { Label, ComboBox, Button } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import React, { useRef, useState, useEffect } from "react";
import "./currentRequest.css";

function SearchBar(props) {
  const serverName = get_servername(props);
  const tableRows = props.tableRows;

  const [filterRow, setfilterRow] = useState([]);
  const [selectedServername, setSelectedServername] = useState("");
  const [comboBoxKey, setComboBoxKey] = useState(0);

  const serverNameArray = serverName.map((serverName) => ({
    key: serverName.ServerName,
    value: serverName.ServerName,
  }));

  const handleServerNameSearchChange = (event) => {
    console.log("handleServerNameSearchChange Event!!" + event.key);
    setSelectedServername(event.key);
  };

  const handleFilterSearch = (in_servername) => {
    console.log("handleFilterSearch Event!!", in_servername);
    const filteredRows = tableRows.filter((row) =>
      row.ServerName.toLowerCase().includes(in_servername.toLowerCase())
    );
    setfilterRow(filteredRows);
    //console.log("filtered row values:: ", filterRow);
  };

  const handleClearFilter = () => {
    setfilterRow([]);
    setSelectedServername("");
    setComboBoxKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    //console.log("filtered row valuesc UseEffect:: ", filterRow);
    props.onChangeFilterRow(filterRow);
  }, [filterRow]);

  return (
    <>
      <div className="SearchBarDiv">
        <Label
          id="SearchBar_label"
          className="verint-regular-label"
          forhtml="SearchBar"
        >
          Search For:
        </Label>

        <ComboBox
          key={comboBoxKey}
          onChange={handleServerNameSearchChange}
          optionKey="value"
          options={serverNameArray}
          placeholder="---------- Servername ----------"
          tooltipText=""
        />

        <div className="SearchButtonDiv">
          <div className="searchButton">
            <Button
              class="btn btn-primary form-control"
              data-testid="verint-button"
              onClick={() => handleFilterSearch(selectedServername)}
              style={{ height: "25px", width: "80px", padding: "inherit" }}
            >
              Search
            </Button>
          </div>

          <div className="clearButton">
            <Button
              data-testid="verint-button"
              onClick={handleClearFilter}
              style={{
                height: "25px",
                width: "30px",
                background: "#999",
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

function get_servername(props) {
  //console.log("serverName array : (get_serverName):: ", props);
  var jsonData = props.tableRows;
  const serverNames = [];

  jsonData.forEach((index) => {
    serverNames.push({
      ServerName: index.ServerName,
    });
  });

  //console.log("serverNames::::get_serverNames: ", serverNames);
  return serverNames;
}

export default SearchBar;
