import React, { useEffect, useState } from "react";
import {
  CheckboxV2,
  LuxStylesProvider,
  Label,
} from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

function TandCCheckbox(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const open = Boolean(anchorEl);
  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (should_display_TandC_checkbox(props) === false) {
      props.setTandCSelected(false);
    }
    if (props.environmentSelected === "64") {
      setChecked(true);
      setIsDisabled(true);
    } else {
      setChecked(props.TandCSelected);
      setIsDisabled(props.TandCSelectedue);
    }
    // checking for the editform property from current req
    if (props.editForm === "true") {
      setChecked(true);
    }
  });

  // If selected environment is cannot use the checkbox, hide the checkbox
  if (should_display_TandC_checkbox(props) === false) {
    return null;
  }

  return (
    <>
      {/*  TandC Checkbox */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="TandC"
            style={{ marginTop: "3px" }}
            checked={checked}
            name="TandC"
            type="checkbox"
            value={props.TandCSelected}
            disabled={props.disabled}
            onChange={(e) => {
              props.setTandCSelected(!props.TandCSelected);
            }}
          ></CheckboxV2>

          <Label
            id="TandC_label"
            className="verint-regular-label"
            forhtml="TandC"
          >
            Customer Terms & Conditions{" "}
          </Label>
          <OverlayText
            tooltipText={
              "By selecting this option the system will inject agreed upon legal T&Cs. That upon the first login of each day, will display our T&Cs and require acceptance in order to use the environment. Thus allowing these types of labs to be given to customers to try the product out. into the system. This is also laid out in the T&Cs during login. Note: Under no circumstances must the customer put sensitive data"
            }
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default TandCCheckbox;

function should_display_TandC_checkbox(props) {
  //checking the editform property from current request
  if (props.editForm === "true") {
    return true;
  }
  var jsonData = props.environemntRecords;
  for (var i = 0; i < jsonData.Count; i++) {
    if (
      jsonData.Items[i].group_name.includes(props.group) &&
      jsonData.Items[i].template_id === props.environmentSelected
    ) {
      if (jsonData.Items[i].TermsandCondition === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
}
