import {OverflowTooltip, Button } from "@verint/shared-react-components";

import '@verint/lux_icon_font/verint_lux.css';
import React, { useRef } from 'react';

function OverlayText(props) {   
    const header = useRef();
    const tooltipText = props.tooltipText
    const iconName = props.iconName
    return (
        <>
           
            <OverflowTooltip
                    text={tooltipText}
                    textElementRef={header}
                    placement="right"
                    slotProps={{ popper: { className: 'example-class' } }} // This will be passed to the underlying MUI Tooltip component
                    
                    >
                    <div data-testid="tooltipDiv" style={{ width: 'fit-content', padding:"3px" }}>

                        <div
                            className={iconName==undefined?"icon-information":iconName}
                            
                            ref={header}
                            style={{
                            width: '14px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                           
                            }}
                        >
                            { tooltipText}
                        </div>
                       
                    </div>
            </OverflowTooltip>
           
        </>
    );
}


export default OverlayText;


