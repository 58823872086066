// Provides input for the given time which can be extended later if required

import React, { useState } from "react";
import { NumericStepper } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";

// Component created with arrow function making use of hooks
const GivenTime = (props) => {
  const [givenTime, setGivenTime] = useState(props.givenTime);
  const min = 0;
  const max = 40;
  const onChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= min && value <= max) {
      setGivenTime(value);
    }
  };

  // If component is not required, remove it from the form

  if (
    props.item != null &&
    (!props.item.hasOwnProperty("GivenTime") || props.item.GivenTime == "")
  ) {
    return false;
  }

  return (
    <tr className="editForm_table-row">
      <td className="td_row_leftvalue">Given Time</td>
      <td className="td_key" style={{ paddingLeft: "15px" }}>
        <NumericStepper
          data-testid="verint-numeric-stepper"
          id="givenTimeValue"
          defaultValue={givenTime}
          onChange={onChange}
          value={givenTime}
          min={min}
          max={max}
        />
      </td>
    </tr>
  );
};

export default GivenTime;
