import React, { useState, useEffect } from "react";
import {
  Popover,
  CheckboxV2,
  LuxStylesProvider,
  Label,
} from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

function OperationManagerCheckbox(props) {
  const [checked, setChecked] = useState(false);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (display_operation_manager_checkbox(props) === false) {
      setChecked(false);
    }
    // checking for the editform property from current req
    if (props.editForm === "true") {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  // If selected environment is cannot use the checkbox, hide
  if (display_operation_manager_checkbox(props) === false) {
    return null;
  }

  return (
    <>
      {/*  MS Teams Live Recording  */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="OperationManager"
            style={{ marginTop: "3px" }}
            name="OperationManager"
            type="checkbox"
            value={checked}
            disabled={props.disabled}
            checked={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
          ></CheckboxV2>

          <Label
            id="OperationManager_label"
            className="verint-regular-label"
            forhtml="OperationManager"
          >
            Operation Manager
          </Label>
          <OverlayText
            tooltipText={"Select this option if you require operation manager."}
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default OperationManagerCheckbox;

function display_operation_manager_checkbox(props) {
  var jsonData = props.environemntRecords;
  for (var i = 0; i < jsonData.Count; i++) {
    if (
      jsonData.Items[i].group_name.includes(props.group) &&
      jsonData.Items[i].template_id === props.environmentSelected
    ) {
      if (jsonData.Items[i].Operation_Manager_checkbox === true) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}
