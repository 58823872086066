
import { Popover , TooltipV2 , ButtonV2  , ProgressSpinner,IconButtonV2, TextFieldV2} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import { Alert } from '@mui/material';
// import LoadingSpinner from "./LoadingSpinner";
import React, { useRef,useState,useEffect } from 'react';
import $ from 'jquery';
import OverlayText from './OverlayText'


function UrlName(props) {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [UrlName, setUrlName] = useState(null)
    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const color = value == "Available" ? 'green' : value == "Unavailable" ? 'red' : 'black';

    useEffect(() => {
      
        $("#UrlName").attr('maxLength','10')
    }, [UrlName]);
    // TODO: Make function to determine URL name
    if (value != 'Available'|| value == null ) 
        {
            props.setValidationError("error");
        } 
        else 
        {
            props.setValidationError("Available");
        };
    // Check if the values are unavailable or null
    const handleChange = (event)=>{
        if (value != 'Available'|| value == null ) 
        {
            props.setValidationError("error");
        } 
        else 
        {
            props.setValidationError("Available");
        };
        setUrlName(event.target.value)
    }


    function shortname(inShortName,intoken,e){  
        // In order to stop the page from reloading 
        e.preventDefault();
        //Alert for special characters 
        var allowedChars = /^[a-zA-Z0-9]*$/;
        var shortName= inShortName 
        if (!allowedChars.test(shortName)) {
            {
                setValue(<OverlayText iconName="icon-message-status-warning" tooltipText = "Please note special characters are not allowed"></OverlayText>)
                return false;
            }
        }
        if((inShortName.length)>10)
        {
            setValue(<OverlayText iconName="icon-message-status-warning" tooltipText = "Short Name cannot be more then 10 character"></OverlayText>)
            return false;
        }
        //To load the spinning icon
        setIsLoading(true)
        if(shortName){}   
        var options = 
        {
          method: "POST",
          headers: {'Content-Type' : 'application/json'},
          body: `{"shortName" : "${shortName}", "token" : "${intoken}"}`
        }

        //const request = new Request('http://localhost:3004/friendlyURL',options);
        const request = new Request('/friendlyURL',options);
        fetch(request)
        .then(response => response.json())
        .then(data => {
            setValue(data);
            setIsLoading(false);
          })
    
    }
    if(props.urlchecked === false){
        return null
    }

    return <>
            <td className='td_row_rightvalue'>
            <div style={{display:"flex" , paddingBottom:"10px"}}>
                        <TextFieldV2 
                        type="search" placeHolderText="Enter URL..." id="UrlName" name='UrlName' 
                        value={UrlName} maxLength="10"
                        onChange={handleChange} 
                        disabled = {!props.urlchecked}
                        required />
                        
                    <ButtonV2 type="search" id='btnFriendlyURL' style={{background:"#ccc",minWidth:"2.5rem"}} 
                    onClick={e =>{shortname(UrlName,props.token,e)}}> <span class="icon-search" ></span></ButtonV2>
                    {/* // Alert added if the value is unavailable or null */}
                    {props.ValidationError!= 'Available' && props.showAlert && <div sx={{ position: 'relative', ml: 2 }}>
                    
                    {/* <Alert  className="alert_friendlyURL" severity="warning" sx={{ position: 'absolute',  width:'100%', top : '30%', left:'95%', transform: 'translateY(-50%)' }}>
                        Enter Valid ShortName
                    </Alert> */}
                    <OverlayText iconName="icon-message-status-warning" tooltipText = "Enter Valid ShortName"></OverlayText>
                    </div>}
                    <span style={{color: color, fontWeight:'bold', paddingLeft :"10px"}}>{isLoading ? 
                        <ProgressSpinner data-testid="verint-progress-spinner"  /> : value}
                    </span>
            </div>
            </td>            

    </>       
}

export default UrlName;