import { Label ,NumericStepper} from "@verint/shared-react-components";
import '@verint/lux_icon_font/verint_lux.css';
import React, { useState, useEffect } from 'react';
import OverlayText from "./OverlayText";

function DesktopNumber(props) {
    const [numberOfDesktops, setNumberOfDesktops] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const min = 0;
    const max = 20;

    // Reset the value to 0 whenever the props.displayDesktopNumber changes
    useEffect(() => {
        if (!props.editForm) {
        setNumberOfDesktops(0);
        }
    }, [props.displayDesktopNumber]);

    // Updating for Edit Form 
    useEffect(() => {
        if (props.editForm) {
            const parsedValue = parseInt(props.editForm);
            setNumberOfDesktops(parsedValue);
            console.log("Desktops", parsedValue);
        }
    }, [props.editForm]);

    // If component is not required, remove it from the form
    if(props.displayDesktopNumber === false){
        return null
    }

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (value >= min && value <= max) {
            setNumberOfDesktops(value);
        }
    };

    console.log("numberOfDesktops",numberOfDesktops);
    return (
       
            
            <div>
                <NumericStepper
                    type="number"
                    id="NumberOfDesktops" 
                    name="NumberOfDesktops"
                    style={{marginTop :"-9px"}}
                    min={min}
                    max={max}
                    value={numberOfDesktops}
                    onChange={handleChange}
                    // disabled={!props.displayDesktopNumber}
                    disabled= {props.editForm ? true : (!props.displayDesktopNumber) }
                />
            </div>
      
    );
}

export default DesktopNumber;