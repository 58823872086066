import React, { useState } from "react";
import { Popover, TextField, Label } from "@verint/shared-react-components";

import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

function InternalId(props) {
  const [internalId, setInternalId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // TODO: Make function to determine internalId label

  const [helperText, sethelperText] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length === 100) {
      sethelperText("Max limit: 100 characters");
    } else {
      sethelperText("");
    }
  };

  return (
    <>
      {/* Internal Id selection */}

      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <Label
            id="internalID_label"
            className="verint-regular-label"
            forhtml="internalID"
          >
            {determine_field_label(props.group)}{" "}
          </Label>
          <div className="required-field"></div>
          <OverlayText
            tooltipText={
              "For a demo please put the SFID and prospective customer. For billable customer training put the SO number and for everything else add a short, yet descriptive reason. For example: 'Testing Feature X' or 'Internal Training Demo Session'."
            }
          ></OverlayText>
        </td>

        <td className="td_row_rightvalue">
          {" "}
          <TextField
            style={{ width: "198px" }}
            id="internalID"
            name="TrackingId"
            data-testid="verint-textfield"
            defaultValue=""
            required
            placeHolderText="Enter Internal ID (SO number)"
            inputProps={{
              maxLength: 100,
            }}
            helperText={helperText}
            FormHelperTextProps={{
              style: { color: "red", fontSize: "xx-small" },
            }}
            onChange={handleChange}
          />
        </td>
      </tr>
    </>
  );
}

export default InternalId;

function determine_field_label(group) {
  // A list of template ids for template ids that can deploy this checkbox
  if (
    group === "PreSales" ||
    group === "APAC" ||
    group === "EMEAPreSales" ||
    group === "APACPreSales"
  )
    return "SFDC ID";
  else if (group == "ISV") return "Partner Name";
  else if (group === "VCS") return "Customer";
  else return "Internal ID ";
}
