import React, {useEffect,useState} from 'react';
import { CheckboxV2, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function LMSCheckbox(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_LMS_checkbox(props) === false){
            props.setLmsSelected(false);
        }  
    })

    // If selected environment is cannot use the checkbox, hide the checkbox
    if(should_display_LMS_checkbox(props) === false){
        return null
    }

    return <>
        {/*  LMS Checkbox */}
        <tr className='reqForm_tablerow'>
                <td className='td_row_leftvalue'>
                        <CheckboxV2 className="verint-CheckboxV2-regular" id="LMS" style={{marginTop :"3px"}}
                                name="LMS" type = "checkbox"  value = {props.lmsSelected} 
                                onChange={(e) => {props.setLmsSelected(!props.lmsSelected);}}></CheckboxV2>

                        <Label id="LMS_label"className="verint-regular-label"   forhtml="LMS">Lab Hours </Label>
                        <OverlayText tooltipText={"Use this option to determine how many hours your lab will last, Once the hour count reaches zero, your lab will terminate."}></OverlayText>
                             
                </td>
            </tr>
    </>        
}

export default LMSCheckbox;
function should_display_LMS_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].LMS_Checkbox  === true &&  get_admin_emails(props)){
                return true
            }
            else 
            {
                return false
            }
        }      
    }
    return false
}
//Validate if current user is part of admin emails 
function get_admin_emails(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var lower_case_email = props.userEmail.toLowerCase();
            }
            catch{}
            if(jsonData.Items[i].LMS_admin_emails.includes(lower_case_email)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}

