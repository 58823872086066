import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
// import App from './App';
import ReqForm from './ReqForm'
import CurrentRequestHome from './currentRequest/Components/home.jsx'
// import './App.css';
import SystemInformation from './pages/SystemInformation'

class Home extends Component {
render() {
	return (
	<Router>
        <div>
		<Routes>
				{/* <Route exact path='/' element={< App />}></Route> */}
				<Route exact path='/' element={< ReqForm />}></Route> 
				<Route exact path='/form' element={< ReqForm />}></Route>
				{/* <Route exact path='/requests-table' element={< CurrentRequestHome />}></Route> */}
				<Route path="/system_information" element={ <SystemInformation /> } />				
		</Routes>
		</div>
	</Router>
);
}
}

export default Home;
