import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function MobileGatewayCheckbox(props) {
    
    const [checked, setChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    let is_davinci = get_daVinci_templateId(props);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(should_display_mgw_checkbox(props) === false){
            setChecked(false)
        }
        // checking for the editform property from current req 
        if(props.editForm === "true"){
            setIsDisabled(true)
            setChecked(true)
        } 
        if(is_davinci === true){
            setChecked(true)
            setIsDisabled(true)
        } 
        else if(is_davinci === false){
            setIsDisabled(false)
            setChecked(false)
          }       
    }, [props.environmentSelected])

  
    if(should_display_mgw_checkbox(props) === false){
        return null
    }

    return <>
            {/*  Mobile Gateway  */}
            {/* <div className="row" id="MobileGatewayRow" style={{display:"flex"}}> */}
            <CheckboxV2 className="verint-CheckboxV2-regular" id="MobileGateway" 
                name="MobileGateway" type = "checkbox"  value = {checked} disabled={isDisabled} 
                checked={checked}  onChange={(e) => {setChecked(!checked);}}></CheckboxV2> 

            <Label id="MobileGateway_label" className="verint-regular-label"   forhtml="MobileGateway">Mobile App </Label>
            <OverlayText tooltipText={"Select this option if you require 'Mobile App' capability."}></OverlayText>
                   
            {/* </div> */}
    </>        
}

export default MobileGatewayCheckbox;

function should_display_mgw_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Mobile_App  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

//Validate if templateId has preselected DaVinci 
function get_daVinci_templateId(props)
{
    var jsonData = props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var daVinci_templateId = props.environmentSelected;
            }
            catch{}
            if(jsonData.Items[i].davinci_labs_templateIds.includes(daVinci_templateId)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}

