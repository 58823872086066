import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import OverlayText from "./OverlayText"
import '@verint/lux_icon_font/verint_lux.css';


function AWSLiveRecordingCheckbox(props) {
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_lr_checkbox(props) === false){
            props.setAwsliveRecording(false)
        } 
        // checking for the editform property from current req 
         if(props.editForm === "true"){
            setIsDisabled(true)
        }         
    }, [props.environmentSelected])

    useEffect(() => {
        setIsDisabled(props.liveRecording);
    }, [props.liveRecording]);

     // If selected environment is cannot use the checkbox, hide
       if(should_display_lr_checkbox(props) === false){
        return null
    }

    return <>
      
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                    <CheckboxV2 className="verint-CheckboxV2-regular" id="AWSLiveRecording" style={{marginTop :"3px"}}
                            name="AWSLiveRecording" type = "checkbox" value = {props.awsliveRecording}  
                             disabled={isDisabled} checked = {props.awsliveRecording}  
                             onChange={(e) => {props.setAwsliveRecording(!props.awsliveRecording);}} ></CheckboxV2> 
                    
                    <Label id="AWSLiveRecording_label"className="verint-regular-label" forhtml="AWSLiveRecording"  >AWS Live Recording </Label>
                    <OverlayText tooltipText={"Select this option if you require the AWS LiveRecording"}></OverlayText>
                

          </td>      
      </tr>
    </>        
}

export default AWSLiveRecordingCheckbox;

function should_display_lr_checkbox(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].AWS_LiveRecording === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

