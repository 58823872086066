import React, { useRef, useEffect,Component,useState } from "react";
import { DatePickerV2, LuxStylesProvider,Label, Popover} from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import $ from 'jquery';

function EndDate(props) {

    const isAdmin = props.is_superAdmin;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const currentDate = new Date(props.startDate);
    const endDatevalue = new Date(props.endDate);
    const threeMonthsFromNow = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());  
    const maxDate = isAdmin ? null : threeMonthsFromNow; // If user is not an admin, set maxDate to 3 months from currentDate
    useEffect(() => {
         
      $("[name='TeardownDate']").attr('required','')
    }, []);
    return <>
                 
                  <LuxStylesProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePickerV2 endDate={props.endDate} setDate = {props.setEndDate} startDate={props.startDate} 
                        onChange={(date) =>{props.setEndDate(date)}} 
                        format='yyyy-MM-dd' minDate={props.startDate} maxDate={maxDate} selected={props.endDate} 
                        onKeyDown={() => {return false}} name="TeardownDate"
                        required/></LocalizationProvider>
                  </LuxStylesProvider>
                {/* </div>
            </div> */}
    </>        
}

export default EndDate;