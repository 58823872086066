import React, { useState, useEffect } from "react";
import { Popover, TextField, Label } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";

function EmailAddress(props) {
  const [EmailAddress, setEmailAddress] = useState(props.emailAddress || null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailError, setEmailError] = useState("");

  const open = Boolean(anchorEl);

  console.log("email address is", EmailAddress);

  // Removes the old DesktopNumber component and resets its value to 0 whenever the props.desktopRequired changes.
  useEffect(() => {
    if (props.forwardEmail) {
      return () => {
        setEmailAddress();
      };
    }
  }, [props.forwardEmail]);

  // Update emailAddress when props.emailAddress changes
  useEffect(() => {
    setEmailAddress(props.emailAddress);
  }, [props.emailAddress]);

  // If component is not required, remove it from the form
  if (props.forwardEmail === false) {
    return null;
  }

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailAddress(email);
    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };

  return (
    <div>
      {props.forwardEmail && (
        <TextField
          style={{ width: "198px", marginTop: "-9px" }}
          id="EmailAddress"
          name="EmailAddress"
          data-testid="verint-textfield"
          onChange={handleEmailChange}
          value={EmailAddress}
          required
          // disabled={!props.forwardEmail && !props.EmailAddress}
          placeholder="Enter email address.."
          error={!!emailError}
          helperText={emailError}
        />
      )}
      {!props.forwardEmail && (
        <TextField
          style={{ width: "198px", marginTop: "-9px" }}
          id="EmailAddress"
          name="EmailAddress"
          data-testid="verint-textfield"
          onChange={handleEmailChange}
          value={EmailAddress}
          required
          disabled={!props.forwardEmail}
          placeholder="Enter email address.."
          error={!!emailError}
          helperText={emailError}
        />
      )}
    </div>
  );
}
export default EmailAddress;
