import React, { useState, useEffect } from 'react';
import $ from 'jquery';
function CurrentTime(props) {
    const [currentTime, setCurrentDateTime] = useState('');
    
    useEffect(() => {
        // This effect runs once when the component mounts
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };

        const formattedDateTime = new Date().toLocaleString('en-US', options);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCurrentDateTime(`${formattedDateTime}, (${timeZone})`);
    }, []);
     //Hide ok cancel button
     $("#requestform").parent().next().hide()
    console.log("INFO || The Current time ", currentTime)
    return (
        <>
            
                    <input
                        className="input1 form-control"
                        id="CurrentTime"
                        name="CurrentTime"
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            width: '150',
                            display : "none"
                        }}
                        value={currentTime}
                        readOnly
                    />
           
        </>
    );
}

export default CurrentTime;
